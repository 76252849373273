@use "src/styles/typography";
@use "src/styles/palette";
@use "src/styles/layout";

.block {
  display: flex;
  align-items: center;
  width: 100%;
  column-gap: 2rem;
  row-gap: 1rem;
  flex-direction: column;
  margin-bottom: 2rem;

  @media (min-width: layout.breakpoint(md)) {
    flex-direction: row;
    margin-top: 2rem;
    margin-bottom: 4rem;
    background-color: palette.grey(light);
    border-radius: 0.25rem;
  }
}

.image {
  width: 100%;

  img {
    height: 100%;
  }

  @media (min-width: layout.breakpoint(md)) {
    width: 41.66%;
    margin-left: 2rem;
    margin-top: -2rem;
    margin-bottom: -2rem;
  }
}

.text {
  width: 100%;

  @media (min-width: layout.breakpoint(md)) {
    width: 58.33%;
    margin-left: 2rem;
    padding: 2rem 0;
  }
}

.title {
  @extend .header_2;

  line-height: 100%;
  margin-bottom: 2rem;
}

.caption {
  @extend .paragraph;

  font-weight: 700;
  margin-bottom: 1rem;
}

.button {
  @media (max-width: layout.breakpoint(md)) {
    display: block;
    text-align: center;
  }
}

.usps {
  margin-bottom: 2.5rem;
  line-height: 1.5;

  ul {
    list-style-type: none;
    padding-left: 1rem;

    li {
      @extend .paragraph;

      margin-bottom: 0.75rem;
      font-weight: 700;
      position: relative;
      padding-left: 1rem;

      &:before {
        //noinspection CssUnknownTarget
        content: url("/icons/check.svg");
        display: block;
        width: 1rem;
        height: 1rem;
        position: absolute;
        left: -0.75rem;
        top: 0;
        line-height: 133%;
      }
    }
  }
}
