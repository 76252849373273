@use "src/styles/typography";
@use "src/styles/palette";

.image_placeholder {
  @extend .header_6;

  background-color: palette.grey("dark");
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-transform: uppercase;
  border-radius: .25rem;
  text-align: center;
}