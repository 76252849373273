@use "src/styles/palette";
@use "src/styles/typography";
@use "src/styles/layout";

.hero {
  position: relative;
  overflow: hidden;
  margin-bottom: 5rem;
  @media (max-width: layout.breakpoint(md)) {
    margin-bottom: 2rem;
  }
}

.container {
  @include layout.container();
}

.grid {
  @include layout.grid();
  gap: 2rem;

  @media (min-width: layout.breakpoint(lg)) {
    @include layout.grid-gap($md: 1);
  }
}

.image_column {
  @include layout.column($md: 6);

  position: relative;
  display: flex;
  justify-content: center;
  padding: 2rem;
  height: auto;

  @media (max-width: layout.breakpoint(md)) {
    padding: 1rem;
    order: 2;
  }
}

.text_column {
  @include layout.column($md: 5);
}

.title {
  @extend .header_1;
  margin-bottom: 1rem;

  @media (max-width: layout.breakpoint(md)) {
    font-size: 3rem !important;
  }
}

.intro_text {
  @extend .paragraph;
}

.image_background {
  height: 50%;
  background-color: palette.brand(primary);
  position: absolute;
  width: 100%;
  z-index: -1;
  border-radius: 0.25rem;
  top: 0;
}

.image_wrapper {
  width: 100%;

  img {
    object-fit: cover;
    height: 100%;
  }
}

.image {
  height: 100%;
}

[data-theme="750"] {
  .hero {
    background: var(--750-gradient-03-reversed);

    .text_column {
      color: #fff;

      .title {
        text-transform: uppercase;
        line-height: 1;
        color: #fff;
      }
      .emphasis {
        color: #fff;
      }
    }
  }

  .intro_text {
    color: #fff;
    ul, ol {
      li {
        color: #fff;
      }
    }
  }

  .text_column {
    @include layout.column($md: 7);
    padding: 7.5rem 1rem 5rem;

    @media (min-width: layout.breakpoint(sm)) {
      padding: 12rem 1rem 6.25rem;
    }

    @media (min-width: layout.breakpoint(md)) {
      padding: 6.25rem 1rem 6.25rem;
    }
  }

  .image_column {
    display: none;
  }

  .desktop_banner {
    @media (min-width: layout.breakpoint(md)) {
      max-width: 580px;
    }

    @media (min-width: layout.breakpoint(lg)) {
      max-width: 732px;
    }
  }
}
