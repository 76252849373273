@use "src/styles/layout";

.container {
  @include layout.container();
}

.grid {
  @include layout.grid();
}

.column {
  @include layout.column();
}

.column_usps {
  @include layout.column();
  margin: 3rem 0;

  @media (max-width: layout.breakpoint(md)) {
    margin: 2rem 0;
  }
}

.offset {
  height: 2.5rem;
}
