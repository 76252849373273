@use "src/styles/palette";
@use "src/styles/typography";
@use "src/styles/layout";

.block {
  background-color: palette.grey(light);
  position: relative;
  padding: 2.5rem 2.5rem 2.5rem 2.8125rem;
  border-radius: 0.3125rem;
  overflow: hidden;
}

.border {
  position: absolute;
  top: 0;
  left: 0;
  width: 0.3125rem;
  height: 100%;
  background-color: palette.brand(primary);
}

.title {
  @extend .header_4;
  margin: 0 0 1.5rem;
}

.items {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;
  gap: 2.5rem;

  @media (max-width: layout.breakpoint(md)) {
    flex-direction: column;
    align-items: flex-start;
    gap: 1.25rem;
  }
}

.item {
  display: inline-flex;
  align-items: flex-start;
  justify-content: flex-start;
  column-gap: 0.625rem;
}

.icon {
  width: 1.6875rem;
  height: 1.6875rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  flex: 1 1 0;
  line-height: 1.5;
}